export const groups = {
  '01': 'Nadia Alleman',
  '02': 'Lisa London',
  '03': 'Beth Surowiec',
  '04': 'Jamie Myers',
  '05': 'Mark Vogel',
  '06': 'Chip Lister ',
  '07': 'Dana Colbert',
  '08': 'Christine Drummond',
  '09': 'Corporate Jobs',
  10: 'Glenn Staada',
  // 11: 'Eric Jensen',
  11: 'Stacy Wood',
  12: 'Greg Halperin',
  13: 'Connor Duffey',
  14: 'Gennifer Jackson',
  15: 'Shayna Beckwith',
  16: 'Erica Pondillo',
  17: 'Shari Aaron',
  // 18: 'Radius',
  29: 'Lincoln Ruchti',
  30: 'Jon Weeks',
  // 31: 'Radius',
  // 32: 'Radius',
  // 33: 'Radius',
  34: 'Nitin Ladva',
  35: 'Strive',
  // 36: 'Radius',
  41: 'Michael Patterson',
  42: 'Susan Hart',
  43: 'Chris Marketo',
  44: 'Curtis Frazier',
  45: 'Carrie Angiolet',
  46: 'Caroline Pendry',
  47: 'Regina Woodall',
  49: 'Lisa Nicolosi',
  // 45: 'Radius',
  70: 'Judy Hoffman',
  71: 'John Delgado',
  73: 'Mike Jennings',
  // 80: 'Cindy Han',
  // 82: 'Leon Zhang',
  80: 'Mike Alcock',
  81: 'Kolin Watts',
  82: 'Warren Ciabattoni',
  84: 'Bryan Krulikowski',
  92: 'Brad Weiss',
  93: 'Brook Finck',
  94: 'Joanne Suh',
  99: 'Unassigned',
}
